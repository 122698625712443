<app-navbar [navClass]="'defaultscroll is-sticky'" [navLight]="true"></app-navbar>
<section class="relative table w-full items-center py-36 bg-top bg-no-repeat bg-cover" style="background-image: url('assets/images/hero/pages.jpg');">
    <div class="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 class="mb-3 text-4xl leading-normal tracking-wider font-semibold text-white">About Us</h3>

            <p class="text-slate-400 text-lg max-w-xl mx-auto">Believe in Craftsmanship and Luxurious Design.</p>
        </div><!--end grid-->
    </div><!--end container-->
    
    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><a routerLink="/">Cartzio</a></li>
            <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">About</li>
        </ul>
    </div>
</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div class="lg:col-span-5 md:col-span-6">
                <img src="assets/images/ab1.jpg" class="rounded-t-full shadow-md dark:shadow-gray-800" alt="">
            </div>

            <div class="lg:col-span-7 md:col-span-6">
                <div class="lg:ms-8">
                    <h6 class="text-orange-500 font-semibold uppercase text-lg">Our Shop</h6>
                    <h5 class="font-semibold text-3xl leading-normal my-4">Focusing on Quality <br> Material, Good Design</h5>
                    <p class="text-slate-400 max-w-xl">Donec non interdum nisl. Sed ut est ac lacus sodales convallis. Nam non velit justo. Mauris vel ultrices tortor. Proin bibendum magna porttitor porttitor suscipit. Praesent sit amet consequat eros. Quisque ullamcorper ornare vulputate. Nam sodales sem id diam sollicitudin, id lobortis tellus tincidunt.</p>

                    <div class="flex items-center mt-6">
                        <i data-feather="phone" class="w-6 h-6 me-4"></i>
                        <div class="">
                            <h5 class="title font-bold mb-0">Phone</h5>
                            <a href="tel:+152534-468-854" class="tracking-wide text-orange-500">+152 534-468-854</a>
                        </div>
                    </div>
                    
                    <div class="flex items-center mt-6">
                        <i data-feather="map-pin" class="w-6 h-6 me-4"></i>
                        <div class="">
                            <h5 class="title font-bold mb-0">Location</h5>
                            <a (click)="onModalClick()" class="tracking-wide text-center text-orange-500 lightbox">View on Google map</a>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div class="lg:col-span-5 md:col-span-6 md:order-2 order-1">
                <img src="assets/images/ab2.jpg" class="rounded-b-full shadow-md dark:shadow-gray-800" alt="">
            </div>

            <div class="lg:col-span-7 md:col-span-6 md:order-1 order-2">
                <h6 class="text-orange-500 font-semibold uppercase text-lg">Founder</h6>
                <h5 class="font-semibold text-3xl leading-normal my-4">Maria J. Rose</h5>
                <p class="text-slate-400 max-w-xl">Donec non interdum nisl. Sed ut est ac lacus sodales convallis. Nam non velit justo. Mauris vel ultrices tortor. Proin bibendum magna porttitor porttitor suscipit. Praesent sit amet consequat eros. Quisque ullamcorper ornare vulputate. Nam sodales sem id diam sollicitudin, id lobortis tellus tincidunt.</p>

                <ul class="list-none mt-6 space-x-3">
                    <li class="inline"><a href="https://dribbble.com/shreethemes" target="_blank" class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"><i data-feather="dribbble" class="size-5 align-middle" title="dribbble"></i></a></li>
                    <li class="inline"><a href="http://linkedin.com/company/shreethemes" target="_blank" class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"><i data-feather="linkedin" class="size-5 align-middle" title="Linkedin"></i></a></li>
                    <li class="inline"><a href="https://www.facebook.com/shreethemes" target="_blank" class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"><i data-feather="facebook" class="size-5 align-middle" title="facebook"></i></a></li>
                    <li class="inline"><a href="https://www.instagram.com/shreethemes/" target="_blank" class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"><i data-feather="instagram" class="size-5 align-middle" title="instagram"></i></a></li>
                    <li class="inline"><a href="https://twitter.com/shreethemes" target="_blank" class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"><i data-feather="twitter" class="size-5 align-middle" title="twitter"></i></a></li>
                </ul><!--end icon-->
            </div>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="grid grid-cols-1 justify-center text-center mb-4">
            <h6 class="text-orange-500 font-semibold uppercase text-lg">Our Promise</h6>
            <h5 class="font-semibold text-3xl leading-normal my-4">We Designed and <br> Developed Products</h5>
        </div><!--end grid-->

        <div class="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
            <!-- Content -->
            <div *ngFor="let item of promiseData" class="p-6 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-md bg-white dark:bg-slate-900">
                <i class="text-4xl text-orange-500" [ngClass]="item.icon"></i>

                <div class="content mt-6">
                    <a class="title h5 text-xl font-medium hover:text-orange-500">{{item.title}}</a>
                    <p class="text-slate-400 mt-3">{{item.desc}}</p>
                    
                    <div class="mt-4">
                        <a class="text-orange-500">Read More <i class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="grid grid-cols-1 justify-center text-center mb-4">
            <h6 class="text-orange-500 font-semibold uppercase text-lg">Our Minds</h6>
            <h5 class="font-semibold text-3xl leading-normal my-4">Meet Our Team Members</h5>
        </div><!--end grid-->

        <div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div *ngFor="let item of teamData" class="lg:col-span-3 md:col-span-6">
                <div class="group text-center">
                    <div class="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                        <img [src]="item.image" class="" alt="">
                        <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>

                        <ul class="list-none absolute start-0 end-0 -bottom-20 group-hover:bottom-5 duration-500 space-x-1">
                            <li class="inline"><a class="size-8 inline-flex items-center justify-center align-middle rounded-full bg-orange-500 text-white"><i data-feather="facebook" class="h-4 w-4"></i></a></li>
                            <li class="inline"><a class="size-8 inline-flex items-center justify-center align-middle rounded-full bg-orange-500 text-white"><i data-feather="instagram" class="h-4 w-4"></i></a></li>
                            <li class="inline"><a class="size-8 inline-flex items-center justify-center align-middle rounded-full bg-orange-500 text-white"><i data-feather="linkedin" class="h-4 w-4"></i></a></li>
                        </ul><!--end icon-->
                    </div>

                    <div class="content mt-3">
                        <a class="text-lg font-semibold hover:text-orange-500 duration-500">{{item.name}}</a>
                        <p class="text-slate-400">{{item.possition}}</p>
                    </div>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
<div *ngIf="isModal" class="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
    <div class="w-full h-full px-5 md:px-40 md-py-20 py-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55431.05581015953!2d-95.461302!3d29.735948000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c36647a52ab1%3A0x70a301678672cb!2sBriargrove%20Park%2C%20Houston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1710322657489!5m2!1sen!2sin" width="100%" height="100%" title="myfram" loading="lazy">
        </iframe>
    </div>
    <button class="text-slate-400 absolute top-[20px] right-[20px]" (click)="onModalClick()">
        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="size-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </button>
</div>