<app-tagline></app-tagline>
<app-navbar [navClass]="'defaultscroll is-sticky tagline-height'"></app-navbar>
<section class="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Mens Brown Jecket</h3>
        </div><!--end grid-->

        <div class="relative mt-3">
            <ul class="tracking-[0.5px] mb-0 inline-block">
                <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Cartzio</a></li>
                <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/shop-grid">Store</a></li>
                <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Mens Brown Jecket</li>
            </ul>
        </div>
    </div><!--end container-->
</section>
<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6">
            <div class="lg:col-span-5">
                <div class="grid md:grid-cols-12 gap-3">
                    <div class="md:col-span-12">
                        <a href="assets/images/shop/mens-jecket.jpg" class="lightbox duration-500 group-hover:scale-105" title="">
                            <img src="assets/images/shop/mens-jecket.jpg" class="shadow dark:shadow-gray-700" alt="Mens Brown Jecket">
                        </a>
                    </div>
                    <div class="md:col-span-6">
                        <a href="assets/images/shop/mens-jecket-3.jpg" class="lightbox duration-500 group-hover:scale-105" title="">
                            <img src="assets/images/shop/mens-jecket-3.jpg" class="shadow dark:shadow-gray-700" alt="Mens Brown Jecket">
                        </a>
                    </div>
                    <div class="md:col-span-6">
                        <a href="assets/images/shop/mens-jecket-left.jpg" class="lightbox duration-500 group-hover:scale-105" title="">
                            <img src="assets/images/shop/mens-jecket-left.jpg" class="shadow dark:shadow-gray-700" alt="Mens Brown Jecket">
                        </a>
                    </div>
                    <div class="md:col-span-12">
                        <a href="assets/images/shop/mens-jecket-back.jpg" class="lightbox duration-500 group-hover:scale-105" title="">
                            <img src="assets/images/shop/mens-jecket-back.jpg" class="shadow dark:shadow-gray-700" alt="Mens Brown Jecket">
                        </a>
                    </div>
                    <div class="md:col-span-12">
                        <a href="assets/images/shop/mens-jecket-4.jpg" class="lightbox duration-500 group-hover:scale-105" title="">
                            <img src="assets/images/shop/mens-jecket-4.jpg" class="shadow dark:shadow-gray-700" alt="Mens Brown Jecket">
                        </a>
                    </div>
                </div>
            </div>

            <div class="lg:col-span-7">
                <div class="lg:ms-6 sticky top-20">
                   <app-product-detail></app-product-detail>
                </div>
            </div>
        </div>

        <app-product-detail-tab></app-product-detail-tab>
    </div><!--end container-->

    <div class="container lg:mt-24 mt-16">
        <div class="grid grid-cols-1 mb-6 text-center">
            <h3 class="font-semibold text-3xl leading-normal">New Arrival Items</h3>
        </div><!--end grid-->

        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 pt-6">
            <div *ngFor="let item of product | slice : 7 : 11" class="group">
                <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                    <img [src]="item.image" class="group-hover:scale-110 duration-500" alt="">

                    <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                        <a routerLink="/shop-cart" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md">Add to Cart</a>
                    </div>

                    <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                        <li><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="heart" class="size-4"></i></a></li>
                        <li class="mt-1"><a routerLink="/shop-item-detail" class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="eye" class="size-4"></i></a></li>
                        <li class="mt-1"><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="bookmark" class="size-4"></i></a></li>
                    </ul>

                    <ul *ngIf="item.tag" class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'New' " class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'Featured' "  class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-emerald-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>
                </div>

                <div class="mt-4">
                    <a routerLink="/product-detail-one" class="hover:text-orange-500 text-lg font-medium">{{item.name}}</a>
                    <div class="flex justify-between items-center mt-1">
                        <p>{{item.desRate}}<del class="text-slate-400">{{item.rate}}</del></p>
                        <ul class="font-medium text-amber-400 list-none">
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
