import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../components/navbar/navbar.component';

@Component({
  selector: 'app-our-store',
  standalone: true,
  imports: [CommonModule,RouterLink,NavbarComponent,],
  templateUrl: './our-store.component.html'
})
export class OurStoreComponent {
  isModal:boolean = false

  onModalClick(){
    this.isModal=!this.isModal;
  }

  storeData = [
    {
      image:'assets/images/ab1.jpg',
      name:'Paynalton, U.S.A.',
      address:'268 St, South New York/NY 98944,',
      country:'United States',
      phone:'+152 534-468-854'
    },
    {
      image:'assets/images/ab3.jpg',
      name:'Paynalton, U.S.A.',
      address:'268 St, South New York/NY 98944,',
      country:'United States',
      phone:'+152 534-468-854'
    },
    {
      image:'assets/images/ab4.jpg',
      name:'Paynalton, U.S.A.',
      address:'268 St, South New York/NY 98944,',
      country:'United States',
      phone:'+152 534-468-854'
    },
  ]
}
