import { CommonModule } from '@angular/common';
import { Component, effect, inject, Input } from '@angular/core';
import { Rate } from '@models/rate.type';
import { ContactsService } from '@services/contact.service';
import { OrdersService } from '@services/orders.service';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'app-rates',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rates.component.html'
})
export class RatesComponent {

  private orderSerrvice = inject(OrdersService)
  rates = this.orderSerrvice.rates
  selectRate = this.orderSerrvice.rateselect

  private productsService = inject(ProductsService)
  shoppingCart = this.productsService.shoppingCart

  private contactsService = inject(ContactsService)
  contactSelect = this.contactsService.contactSelect


  constructor(){
    /* descargar datos desde inicio de prueba */
    //this.orderSerrvice.getRateShipping(this.contactSelect().id)
  }

  rateSelect(item:Rate){

    /* Calcular el Total del Carrito de Compras  */
    let totalShoppinCart:number = 0
    this.shoppingCart().shopping_cart_items.map(item=>{

      totalShoppinCart = totalShoppinCart + (item.price * item.quantity)

    })

    /* Se actualizar El Rate Seleccionado */
    this.selectRate.set(item)
    console.log(this.selectRate())

    /* Se añade costo al carrito de compras */
    const shippingCost = parseFloat(item.price_total)
    const ntotal = totalShoppinCart + shippingCost
    
    this.shoppingCart.update( state=>{

      return {
        ...state,
          shippingCost:shippingCost,
          amount:ntotal

        }
      } 
      
    )
  }
}
