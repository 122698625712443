import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar.component';

@Component({
  selector: 'app-aboutus',
  standalone: true,
  imports: [CommonModule,RouterLink,NavbarComponent],
  templateUrl: './aboutus.component.html',
  styleUrl: './aboutus.component.scss'
})
export class AboutusComponent {
  isModal:boolean = false

  onModalClick(){
    this.isModal=!this.isModal;
  }

  promiseData = [
    {
      icon:'mdi mdi-truck-check-outline',
      title:'Free Shipping',
      desc:'The phrasal sequence of the is now so that many campaign and benefit',
    },
    {
      icon:'mdi mdi-account-wrench-outline',
      title:'24/7 Support',
      desc:'The phrasal sequence of the is now so that many campaign and benefit',
    },
    {
      icon:'mdi mdi-cash-multiple',
      title:'Payment Process',
      desc:'The phrasal sequence of the is now so that many campaign and benefit',
    },

  ]

  teamData = [
    {
      image:'assets/images/client/04.jpg',
      name:'Jack John',
      possition:'Designer'
    },
    {
      image:'assets/images/client/16.jpg',
      name:'Krista John',
      possition:'Designer'
    },
    {
      image:'assets/images/client/06.jpg',
      name:'Roger Jackson',
      possition:'Designer'
    },
    {
      image:'assets/images/client/07.jpg',
      name:'Johnny English',
      possition:'Designer'
    },
  ]
}
