<div class="container relative">
    <div class="grid grid-cols-1 pb-8 text-center">
        <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Find The Help You Need</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Upgrade your style with our curated sets. Choose confidence, embrace your unique look.</p>
    </div><!--end grid-->

    <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-6">
        <div *ngFor="let item of aboutData" class="text-center px-6 mt-6">
            <div class="w-20 h-20 bg-orange-500/5 text-orange-500 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i [attr.data-feather]="item.icon" class="size-[30px]"></i>
            </div>

            <div class="content mt-7">
                <a [href]="item.link" class="title h5 text-lg font-medium hover:text-orange-500">{{item.title}}</a>
                <p class="text-slate-400 mt-3">{{item.desc}}</p>
                
                <div class="mt-5">
                    <a [href]="item.link" class="text-orange-500">Read More <i class="mdi mdi-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div><!--end grid-->
</div>
