<div style="margin: 24px auto ;">
    <table cellpadding="0" cellspacing="0" style="font-family: DM Sans, sans-serif; font-size: 16px; font-weight: 400; width: 600px; border: none; margin: 0 auto; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
        <thead style="padding: 16px; display: block;">
            <tr style="display: block; border: none; text-align: center; font-size: 24px; letter-spacing: 1px;">
                <th scope="col" style="margin: auto; display: inline-flex;"><a routerLink="/"><img src="assets/images/logo-icon-64.png" alt=""></a></th>
            </tr>
        </thead>

        <tbody>
            <tr>
                <td style="justify-content: center; text-align: center; border-top: 1px solid #f3f4f6;">
                    <a routerLink="/" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Home</a>
                    <a routerLink="/shop-cart" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Cart</a>
                    <a routerLink="/sale" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Sale</a>
                    <a routerLink="/contact" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Contact</a>
                </td>
            </tr>
            
            <tr>
                <td style="position: relative; color: #161c2d; font-size: 18px; padding: 70px 0; width: 600px; font-weight: 600; background: url('assets/images/hero/pages.jpg') ; text-align: center; background-size: cover;">
                    <span style="position: absolute; inset: 0; background-color: #161c2d; opacity: 0.4;"></span>
                    <span style="position: relative; z-index: 1;">
                        <h1 style="color: #fff; font-size: 36px;">SALE OUTLET <br> UP TO 75% OFF</h1>

                        <a href="javascript:void(0)" style="padding: 8px 20px; outline: none; text-decoration: none; font-size: 14px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 500; border-radius: 6px; background-color: #f97316; border: 1px solid #f97316; color: #fff;">Shop Now</a>
                    </span>
                </td>
            </tr>

            <tr>
                <td style="padding: 16px 16px 0; color: #161c2d;">
                    <p style="margin: 0; font-size: 18px; font-weight: 500;">Hello, Harry!</p>
                    <p style="margin-bottom: 0; color: #94a3b8;">Please check new offer from Cartzio for you.</p>
                </td>
            </tr>

            <tr>
                <td style="padding: 16px">
                    <span style="font-weight: 500; font-size: 18px;">Blockbuster Deals</span>
                </td>
            </tr>

            <tr>
                <td style="padding: 0 8px;">
                    <table>
                        <tr>
                            <td style="padding: 0 8px 16px; ">
                                <img src="assets/images/shop/apple-smart-watch.jpg" width="100%" style="border-radius: 5px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);" alt=""/>
                                <a  style="text-decoration: none; font-weight: 500; display: block; margin: 10px 0 0; width: 100%; color: #161c2d;">Smart Watch</a>

                                <a href="javascript:void(0)" style="padding: 8px 20px; outline: none; display: inline-block; margin: 10px 0 0; text-decoration: none; font-size: 14px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 500; border-radius: 6px; background-color: #161c2d; border: 1px solid #161c2d; color: #fff;">Shop Now</a>
                            </td>

                            <td style="padding: 0 8px 16px; ">
                                <img src="assets/images/shop/ladies-skirt-pair.jpg" width="100%" style="border-radius: 5px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);" alt=""/>
                                <a  style="text-decoration: none; font-weight: 500; display: block; margin: 10px 0 0; width: 100%; color: #161c2d;">Ladies Skirt</a>

                                <a href="javascript:void(0)" style="padding: 8px 20px; outline: none; display: inline-block; margin: 10px 0 0; text-decoration: none; font-size: 14px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 500; border-radius: 6px; background-color: #161c2d; border: 1px solid #161c2d; color: #fff;">Shop Now</a>
                            </td>
                            
                            <td style="padding: 0 8px 16px; ">
                                <img src="assets/images/shop/luxurious-bag.jpg" width="100%" style="border-radius: 5px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);" alt=""/>
                                <a  style="text-decoration: none; font-weight: 500; display: block; margin: 10px 0 0; width: 100%; color: #161c2d;">Ladies Bag</a>

                                <a href="javascript:void(0)" style="padding: 8px 20px; outline: none; display: inline-block; margin: 10px 0 0; text-decoration: none; font-size: 14px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 500; border-radius: 6px; background-color: #161c2d; border: 1px solid #161c2d; color: #fff;">Shop Now</a>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr>
                <td style="padding: 16px 8px; color: #fff; background-color: #161c2d; text-align: center;">
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="display: inline-flex; align-items: center; margin: 0 10px 10px;">
                                    <span style="font-size: 14px;">Free delivery</span>
                                </td>
            
                                <td style="display: inline-flex; align-items: center; margin: 0 10px 10px;">
                                    <span style="font-size: 14px;">Money-back quarantee</span>
                                </td>
            
                                <td style="display: inline-flex; align-items: center; margin: 0 10px 10px;">
                                    <span style="font-size: 14px;">Secure payments</span>
                                </td>
                            </tr>

                            <tr>
                                <td style="text-align: center;">
                                    <p style="margin: 4px 0 10px;">© {{date}} Cartzio. Designed by <a href="https://shreethemes.in/" target="_blank" style="text-decoration: none; color: #fff;">Shreethemes</a>.</p>
                                </td>
                            </tr>

                            <tr>
                                <td style="text-align: center;">
                                    <a  target="_blank" style="color: #ea580c;">Unsubscribe</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>
