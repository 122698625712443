<div style="margin: 24px auto ;">
    <table cellpadding="0" cellspacing="0" style="font-family: DM Sans, sans-serif; font-size: 16px; font-weight: 400; width: 600px; border: none; margin: 0 auto; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
        <thead style="padding: 16px; display: block;">
            <tr style="display: block; border: none; text-align: center; font-size: 24px; letter-spacing: 1px;">
                <th scope="col" style="margin: auto; display: inline-flex;"><a routerLink="/"><img src="assets/images/logo-icon-64.png" alt=""></a></th>
            </tr>
        </thead>
 
        <tbody>
            <tr>
                <td style="justify-content: center; text-align: center; border-top: 1px solid #f3f4f6;">
                    <a routerLink="/" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Home</a>
                    <a routerLink="/shop-cart" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Cart</a>
                    <a routerLink="/sale" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Sale</a>
                    <a routerLink="/contact" style="text-decoration: none; padding: 16px; display: inline-block; font-weight: 600; color: #161c2d; text-transform: uppercase; font-size: 14px;">Contact</a>
                </td>
            </tr>

            <tr>
                <td style="padding: 24px 16px; background: #f8fafc; display: block; text-align: center;">
                    <img src="assets/images/order-success.svg" width="200px" style="margin: 0 auto;" alt="">
                    <h1 style="font-weight: 600; margin: 16px 0 0; font-size: 32px;">Thank you!</h1>
                </td>
            </tr>

            <tr>
                <td style="padding: 16px 16px 0; color: #161c2d;">
                    <p style="margin: 0; font-size: 18px; font-weight: 500;">Hello, Harry!</p>
                    <p style="margin-bottom: 0; color: #94a3b8;">Thank you for your order success. Payment is proceed successfull and your order is on the way. Please check your order via <span style="font-weight: 500; color: #161c2d;">Transaction ID: 151448HJG5JTUYV5</span></p>
                </td>
            </tr>
            
            <tr>
                <td style="padding: 16px;">
                    <table style="width: 100%; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15); border-radius: 6px; background-color: #fff;">
                        <thead style="text-transform: uppercase; background-color: #f8fafc;">
                            <tr>
                                <th scope="col" style="text-align: left; padding: 10px 16px; border: none;">Product</th>
                                <th scope="col" style="padding: 10px 16px; border: none;">Price</th>
                                <th scope="col" style="padding: 10px 16px; border: none;">Qty</th>
                                <th scope="col" style="text-align: right; padding: 10px 16px; border: none;">Total($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="padding: 16px;">
                                    <span style="display: flex; align-items: center;">
                                        <img src="assets/images/shop/black-print-t-shirt.jpg" style="width: 48px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15); border-radius: 6px;" alt="">
                                        <span style="margin-left: 8px;">
                                            <span style="font-weight: 500;">T-shirt (M)</span>
                                        </span>
                                    </span>
                                </td>
                                <td style="text-align: center; padding: 16px;">$ 280</td>
                                <td style="text-align: center; padding: 16px;">1</td>
                                <td style="text-align: right; padding: 16px;">$ 280</td>
                            </tr>

                            <tr>
                                <td style="padding: 16px;">
                                    <span style="display: flex; align-items: center;">
                                        <img src="assets/images/shop/fashion-shoes-sneaker.jpg" style="width: 48px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15); border-radius: 6px;" alt="">
                                        <span style="margin-left: 8px;">
                                            <span style="font-weight: 500;">Sneaker Shoes</span>
                                        </span>
                                    </span>
                                </td>
                                <td style="text-align: center; padding: 16px;">$ 160</td>
                                <td style="text-align: center; padding: 16px;">1</td>
                                <td style="text-align: right; padding: 16px;">$ 160</td>
                            </tr>

                            <tr>
                                <td style="padding: 16px;">
                                    <span style="display: flex; align-items: center;">
                                        <img src="assets/images/shop/ladies-skirt-pair.jpg" style="width: 48px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15); border-radius: 6px;" alt="">
                                        <span style="margin-left: 8px;">
                                            <span style="font-weight: 500;">Ladies Skirt</span>
                                        </span>
                                    </span>
                                </td>
                                <td style="text-align: center; padding: 16px;">$ 500</td>
                                <td style="text-align: center; padding: 16px;">1</td>
                                <td style="text-align: right; padding: 16px;">$ 500</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>

            <tr>
                <td style="padding: 0 16px 16px;">
                    <table style="width: 240px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15); border-radius: 6px; margin-left: auto;">
                        <tbody>
                            <tr>
                                <td style="text-align: right; padding: 16px;">
                                    <table style="width: 100%;">
                                        <tr style="list-style: none; padding: 0; margin: 0;">
                                            <td style="display: flex; justify-content: space-between; padding: 0 0 10px;">
                                                <span style="font-weight: 500;">Subtotal :</span>
                                                <span style="color: #94a3b8;">$ 940</span>
                                            </td>
                                            <td style="display: flex; justify-content: space-between; padding: 10px 0; border-top: 1px solid #f3f4f6;">
                                                <span style="font-weight: 500;">Taxes :</span>
                                                <span style="color: #94a3b8;">$ 94</span>
                                            </td>
                                            <td style="display: flex; justify-content: space-between; padding: 10px 0 0; border-top: 1px solid #f3f4f6;">
                                                <span style="font-weight: 600;">Total :</span>
                                                <span style="font-weight: 600;">$ 1034</span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>

            <tr>
                <td style="padding: 16px 8px; color: #fff; background-color: #161c2d; text-align: center;">
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="display: inline-flex; align-items: center; margin: 0 10px 10px;">
                                    <span style="font-size: 14px;">Free delivery</span>
                                </td>
            
                                <td style="display: inline-flex; align-items: center; margin: 0 10px 10px;">
                                    <span style="font-size: 14px;">Money-back quarantee</span>
                                </td>
            
                                <td style="display: inline-flex; align-items: center; margin: 0 10px 10px;">
                                    <span style="font-size: 14px;">Secure payments</span>
                                </td>
                            </tr>

                            <tr>
                                <td style="text-align: center;">
                                    <p style="margin: 4px 0 10px;">© {{date}} Cartzio. Designed by <a href="https://shreethemes.in/" target="_blank" style="text-decoration: none; color: #fff;">Shreethemes</a>.</p>
                                </td>
                            </tr>

                            <tr>
                                <td style="text-align: center;">
                                    <a  target="_blank" style="color: #ea580c;">Unsubscribe</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>
