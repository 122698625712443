<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>

<section class="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Productos</h3>
        </div><!--end grid-->

        <div class="relative mt-3">
            <ul class="tracking-[0.5px] mb-0 inline-block">
                <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Inicio</a></li>
                <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Productos</li>
            </ul>
        </div>
    </div><!--end container-->
</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="md:flex justify-between items-center mb-6">
            <span class="font-semibold">Mostrando 1-16 de 40 productos</span>

            <div class="md:flex items-center">
                <label class="font-semibold md:me-2">Ordenado por:</label>
                <select class="form-select form-input md:w-36 w-full md:mt-0 mt-1 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
                    <option value="">Featured</option>
                    <option value="">Vendidos</option>
                    <option value="">A-Z</option>
                    <option value="">Z-A</option>
                    <option value="">Price más Alto</option>
                    <option value="">Price más Bajo</option>
                </select>
            </div>
        </div>
        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
            <div *ngFor="let item of product" class="group">
                <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                    <img [src]="item.image" class="group-hover:scale-110 duration-500" alt="">

                    <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                        <a routerLink="/shop-cart" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md">Agregar al Carrito</a>
                    </div>

                    <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                        <li><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="heart" class="size-4"></i></a></li>
                        <li class="mt-1"><a routerLink="/shop-item-detail" class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="eye" class="size-4"></i></a></li>
                        <li class="mt-1"><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="bookmark" class="size-4"></i></a></li>
                    </ul>

                    <ul *ngIf="item.tag" class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'New' " class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'Featured' "  class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-emerald-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>
                </div>

                <div class="mt-4">
                    <a routerLink="/product-detail-one" class="hover:text-orange-500 text-lg font-medium">{{item.name}}</a>
                    <div class="flex justify-between items-center mt-1">
                        <p>{{item.desRate}}<del class="text-slate-400">{{item.rate}}</del></p>
                        <ul class="font-medium text-amber-400 list-none">
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!--end grid-->

        <div class="grid md:grid-cols-12 grid-cols-1 mt-6">
            <div class="md:col-span-12 text-center">
                <nav aria-label="Page navigation example">
                    <ul class="inline-flex items-center -space-x-px">
                        <li>
                            <a class="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500">
                                <i data-feather="chevron-left" class="size-5 rtl:rotate-180 rtl:-mt-1"></i>
                            </a>
                        </li>
                        <li>
                            <a class="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500">1</a>
                        </li>
                        <li>
                            <a class="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500">2</a>
                        </li>
                        <li>
                            <a aria-current="page" class="z-10 size-[40px] inline-flex justify-center items-center text-white bg-orange-500 border border-orange-500">3</a>
                        </li>
                        <li>
                            <a class="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500">4</a>
                        </li>
                        <li>
                            <a class="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500">5</a>
                        </li>
                        <li>
                            <a class="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500">
                                <i data-feather="chevron-right" class="size-5 rtl:rotate-180 rtl:-mt-1"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div><!--end col-->
        </div><!--end grid-->
    </div><!--end container-->
</section>