<div class="container relative md:mt-24 mt-16">
    <div class="grid grid-cols-1 text-center">
        <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Have Question ? Get in touch!</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Upgrade your style with our curated sets. Choose confidence, embrace your unique look.</p>
    
        <div class="mt-6">
            <a routerLink="/helpcenter-support" class="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md me-2 mt-2"><i data-feather="phone" class="size-4 me-1"></i> Contact us</a>
        </div>
    </div><!--end grid-->
</div>
