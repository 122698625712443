import { Component, inject } from '@angular/core';
import { ProductsService } from '@services/products.service';
import { CategoriesComponent } from "../../components/categories/categories.component";
import { ProductListComponent } from "../../components/product-list/product-list.component";
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CategoriesComponent, ProductListComponent, RouterLink],
  templateUrl: './home.component.html'
})
export class HomeComponent {
  category:string  = null
  private productService = inject(ProductsService)
  
  heroImage = [
    {
      image:'assets/images/categories/ladies-ware.jpg',
      name:'Ropa de Mujer'
    },
    {
      image:'assets/images/categories/kids-ware.jpg',
      name:'Ropa de niños'
    },
    {
      image:'assets/images/categories/mens-ware.jpg',
      name:'Ropa para machos'
    },
  ]

  constructor(){
    this.productService.getShoppingCart()
  }

}
