<nav id="topnav" class={{navClass}} [ngClass]="isSticky ? 'nav-sticky' : ''">
    <div class="container relative">
        <!-- Logo container-->
        <ng-container *ngIf="navLight === true ; else elseTemplate">
            <a class="logo" href="/">
                <span class="inline-block dark:hidden">
                    <img src="assets/images/logo-paynalton.png" class="l-dark" alt="">
                    <img src="assets/images/logo-paynalton.png" class="l-light" alt="">
                </span>
                <img src="assets/images/logo-paynalton.png" class="hidden dark:inline-block" alt="">
            </a>
        </ng-container>
        <ng-template #elseTemplate>
            <a class="logo" href="/">
                <div>
                    <img src="assets/images/logo-paynalton.png" class="h-[50px] inline-block dark:hidden" alt="">
                    <img src="assets/images/logo-paynalton.png" class="h-[50px] hidden dark:inline-block" alt="">
                </div>
            </a>
        </ng-template>
        <!-- End Logo container-->

        <!-- Start Mobile Toggle -->
        <div class="menu-extras">
            <div class="menu-item">
                <a class="navbar-toggle" [ngClass]="toggleManu ? 'open' : '' " id="isToggle" (click)="toggleMenu()">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
            </div>
        </div>
        <!-- End Mobile Toggle -->

        <!--Login button Start-->
        <ul class="buy-button list-none mb-0">
            <li class="dropdown inline-block relative pe-1" (clickOutside)="closeSearchModal()">
                <ng-container *ngIf="navLight === true ; else elseTemplates">
                    <button (click)="searchModal()" class="dropdown-toggle align-middle inline-flex search-dropdown" type="button">
                        <i data-feather="search" class="size-5 dark-icon"></i>
                        <i data-feather="search" class="size-5 white-icon text-white"></i>
                    </button>
                </ng-container>
                <ng-template #elseTemplates>
                    <button class="dropdown-toggle align-middle inline-flex" type="button" (click)="searchModal()">
                        <i data-feather="search" class="size-5"></i>
                    </button>
                </ng-template>
                
                <!-- Dropdown menu -->
                <div class="dropdown-menu absolute overflow-hidden end-0 m-0 mt-5 z-10 md:w-52 w-48 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" [ngClass]="searchManu ? '' : 'hidden' ">
                    <div class="relative">
                        <i data-feather="search" class="absolute size-4 top-[9px] end-3 text-slate-900 dark:text-white"></i>
                        <input type="text"
                            (keydown.enter)="onEnter($event)"
                            class="h-9 px-3 pe-10 w-full border-0 focus:ring-0 outline-none bg-white dark:bg-slate-900" name="s" 
                            id="searchItem" 
                            placeholder="Pres Enter para buscar">
                    </div>
                </div>
            </li>

            <!-- Cart -->
            <li class="dropdown inline-block relative ps-0.5" (clickOutside)="closeCartModal()" >
                <button [disabled]="shoppingCart().shopping_cart_items.length == 0"
                    class="dropdown-toggle size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-green-700 border border-green-700 text-white" type="button" (click)="cartModal()">
                    <i data-feather="shopping-cart" class="h-4 w-4"></i>
                </button>
                <!-- Dropdown menu -->
                <div class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-64 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" [ngClass]="cart ? '' : 'hidden' ">
                    <app-menu-cart></app-menu-cart>
                </div>
            </li>

            <!-- Favorites -->
            <!-- <li class="inline-block ps-0.5" >
                <a href="#" class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-green-700 text-white">
                    <i data-feather="heart" class="h-4 w-4"></i>
                </a>
            </li> -->

            <!-- Menu Acccount -->
                <li class="dropdown inline-block relative ps-0.5" (clickOutside)="closeUserModal()">
                        <button data-dropdown-toggle="dropdown" class="dropdown-toggle items-center" type="button" (click)="userModal()">
                            <span class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full border border-green-700 bg-green-700 text-white">
                                <img src="assets/images/user.png" 
                                class="rounded-full" alt="">
                            </span>
                        </button>
                        <!-- Dropdown menu -->
                        <div class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700" [ngClass]="user ? '' : 'hidden' ">

                            <app-menu-account></app-menu-account>

                        </div>
                    </li><!--end dropdown-->
                </ul>

            <!--Login button End-->

            <div id="navigation" [ngClass]="toggleManu ? 'block' : 'none' ">
                <!-- Navigation Menu-->

                <ul class="navigation-menu" [ngClass]="navLight === true ? 'nav-light' : '' ">

                    <li [ngClass]="manu === '/' ? 'active' : '' "><a routerLink="/" class="sub-menu-item">Inicio</a></li>
                    <li [ngClass]="manu === '/shop-grid-left-sidebar' ? 'active' : '' "><a routerLink="/shop-grid-left-sidebar" class="sub-menu-item">Productos</a></li>
                    <li [ngClass]="manu === '/shop-cart' ? 'active' : '' "><a routerLink="/shop-cart" class="sub-menu-item">Carrito de Compra</a></li>

                </ul><!--end navigation menu-->
            </div><!--end navigation-->
    </div><!--end container-->
</nav>
