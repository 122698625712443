<app-navbar [ngClass]="'defaultscroll is-sticky'" [navLight]="true"></app-navbar>

<section class="relative table w-full py-36 bg-center bg-no-repeat bg-cover" style="background-image: url('assets/images/hero/pages.jpg');">
    <div class="absolute inset-0 bg-black opacity-80"></div>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 class="text-4xl leading-normal tracking-wider font-semibold text-white">Guides & Support</h3>

        </div><!--end grid-->
    </div><!--end container-->
    
    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
           <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><a routerLink="/">Cartzio</a></li>
           <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
           <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><a routerLink="/helpcenter">Helpcenter</a></li>
            <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Guides</li>
        </ul>
    </div>
</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
            <div *ngFor="let item of guidesData" class="">
                <h5 class="font-semibold text-xl">{{item.title}}</h5>
                <ul class="list-none mt-4">
                    <li *ngFor="let el of item.features" class="mt-2"><a class="text-slate-400"><i class="mdi mdi-arrow-right text-orange-500 me-2"></i>{{el}}</a></li>
                </ul>
            </div>
        </div><!--end grid-->
    </div><!--end container-->

   <app-get-in-touch></app-get-in-touch>
</section>
