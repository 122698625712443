<section class="relative bg-orange-500/5">
    <div class="container-fluid relative">
        <div class="grid grid-cols-1">
            <div class="flex flex-col min-h-screen justify-center md:px-10 py-10 px-4">
                <div class="text-center">
                    <a routerLink="/"><img src="assets/images/logo-dark.png" class="mx-auto" alt=""></a>
                </div>
                <div class="title-heading text-center my-auto">
                    <img src="assets/images/error.svg" class="mx-auto w-72" alt="">
                    <h1 class="mt-8 mb-6 md:text-5xl text-3xl font-bold">¿Página no encontrada?</h1>
                    <p class="text-slate-400">Ups, esto es vergonzoso. <br> Parece que no se encontró la página que estabas buscando.</p>
                    
                    <div class="mt-4">
                        <a routerLink="/" 
                        class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-orange-500 hover:bg-orange-600 border-orange-500 hover:border-orange-600 text-white rounded-md">
                        Regresar al Inicio</a>
                    </div>
                </div>
                <div class="text-center">
                    <p class="mb-0 text-slate-400">© {{date}} Paynalton Todos los derechos reservados.</p>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>
<app-back-to-home></app-back-to-home>
