import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../components/navbar/navbar.component';

@Component({
  selector: 'app-blogs',
  standalone: true,
  imports: [CommonModule,RouterLink,NavbarComponent],
  templateUrl: './blogs.component.html',
  styleUrl: './blogs.component.scss'
})
export class BlogsComponent {
  blogData = [
    {
      image:'assets/images/blog/1.jpg',
      date:'13th Sep 2024',
      title:'The History Of Patterned Dresses',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/2.jpg',
      date:'29th Nov 2024',
      title:'Swimsuits For The Whole Family',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/3.jpg',
      date:'29th Dec 2024',
      title:'Good Products For Lovely Girls',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/4.jpg',
      date:'13th March 2024',
      title:'Latest Swimsuit Model This Year',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/5.jpg',
      date:'5th May 2024',
      title:'Summer Travel Fashion 2023',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/6.jpg',
      date:'19th June 2024',
      title:'Exploring the Timeless Allure of Fashion',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/7.jpg',
      date:'20th Sep 2024',
      title:'A Chic Journey Through Fashion Trends',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/8.jpg',
      date:'31st Aug 2024',
      title:'Unique Autumn Fashion Ideas',
      desc:'This is required when, for example, the final text is not yet available.'
    },
    {
      image:'assets/images/blog/9.jpg',
      date:'1st Sep 2024',
      title:'Tips For Wearing Loose T-shirts',
      desc:'This is required when, for example, the final text is not yet available.'
    },
  ]
}
