<!-- <app-tagline></app-tagline> -->
<app-navbar [navClass] = "'defaultscroll is-sticky'"></app-navbar>

<section class="relative md:pt-32">

    <app-categories></app-categories>

</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid items-end md:grid-cols-2 mb-6">
            <div class="md:text-start text-center">
                <h5 class="font-semibold text-3xl leading-normal mb-4">Productos de tendencia</h5>
                <p class="text-slate-400 max-w-xl">Compre los últimos productos de los artículos más populares</p>
            </div>

            <div class="md:text-end">
                <a routerLink="/shop-grid" class="text-slate-400 hover:text-orange-500 hidden md:block">Ver más productos <i class="mdi mdi-arrow-right"></i></a>
            </div>
        </div><!--end grid-->


        <app-product-list></app-product-list>


        <div class="grid grid-cols-1 mt-5">
            <div class="text-center">
                <a routerLink="/shop-grid" class="text-slate-400 hover:text-orange-500 block md:hidden">Ver más productos <i class="mdi mdi-arrow-right"></i></a>
            </div>
        </div>
    </div><!--end container-->
</section>


