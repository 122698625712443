import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { CommonModule } from '@angular/common';
import { User } from '../../core/models/auth.type';

@Component({
  selector: 'app-menu-account',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './menu-account.component.html'
})
export class MenuAccountComponent {

  currentUser:any

  constructor(){
    
    const currentUser = localStorage.getItem('currentUser')

    if (currentUser) {

      this.currentUser = JSON.parse(currentUser)

    }
  }
}
