<section class="md:h-screen py-36 flex items-center bg-orange-500/10 dark:bg-orange-500/20 bg-center bg-no-repeat bg-cover" style="background-image: url('assets/images/hero/bg-shape.png');">
    <div class="container relative">
        <div class="grid grid-cols-1">
            <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div class="grid md:grid-cols-2 grid-cols-1 items-center">
                    <div class="relative md:shrink-0">
                        <img class="lg:h-full h-full w-full object-cover md:h-[34rem]" src="assets/images/login.jpg" alt="">
                    </div>

                    <div class="p-8 lg:px-20">
                        <div class="text-center">
                            <a >
                                <img src="assets/images/logo-paynalton.png" class="h-[50px] mx-auto block dark:hidden" alt="">
                                <img src="assets/images/logo-paynalton.png" class="h-[50px] mx-auto hidden dark:block" alt="">
                            </a>
                        </div>

                        <form [formGroup]="form" class="text-start lg:py-20 py-8" (ngSubmit)="onSubmit()">
                            <div class="grid grid-cols-1">
                                <div class="mb-4">
                                    <label class="font-semibold" for="LoginEmail">Email:</label>
                                    <input 
                                        id="LoginEmail" 
                                        type="email" 
                                        class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" 
                                        placeholder="email@ejemplo.com"
                                        formControlName="email"
                                    >
                                    <div *ngIf="form.get('email').invalid && form.get('email').touched">
                                        Email es requerido.
                                      </div>
                                </div>

                                <div class="mb-4">
                                    <label class="font-semibold" for="LoginPassword">Contraseña:</label>
                                    <input 
                                        id="LoginPassword" 
                                        type="password" 
                                        class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" 
                                        placeholder="escribir contraseña"
                                        formControlName="password"
                                    >
                                    <div *ngIf="form.get('password').invalid && form.get('password').touched">
                                        Email es requerido.
                                    </div>
                                </div>

                                <!-- <div class="flex justify-between mb-4" >
                                    <div class="flex items-center mb-0">
                                        <input class="form-checkbox rounded border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe">
                                        <label class="form-checkbox-label text-slate-400" for="RememberMe">Recordarme</label>
                                    </div>
                                    <p class="text-slate-400 mb-0"><a routerLink="/forgot-password" class="text-slate-400">Olvidé mi password ?</a></p>
                                </div> -->

                                <div class="mb-4">
                                    <button 
                                        type="submit" 
                                        class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-green-700 text-white rounded-md w-full" 
                                        [disabled]="form.invalid"
                                    >Entrar</button>

                                </div>

                                <div class="text-center">
                                    <span class="text-slate-400 me-2">No tienes una cuenta?</span> 
                                        <a routerLink="/signup" class="text-black dark:text-white font-bold inline-block">
                                            Registrarte</a>
                                </div>
                            </div>
                        </form>

                        <div class="text-center">
                            <p class="mb-0 text-slate-400">© {{date}} Paynalton Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-back-to-home></app-back-to-home>
