<app-navbar></app-navbar>
<section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 text-center mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Compare Products</h3>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Cartzio</a></li>
            <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
            <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Compare Products</li>
        </ul>
    </div>
</section>
<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid grid-cols-1">
            <div class="rounded p-6 shadow dark:shadow-gray-800">
                <p class="text-slate-400">No products were added to the compare page. <a class="font-medium text-orange-500">Back to shopping</a></p>
            </div>
        </div>
    </div><!--end container-->
</section>