<app-navbar [navClass]="'defaultscroll is-sticky'" [navLight]="true"></app-navbar>
<section class="relative table w-full py-36 lg:py-44 bg-no-repeat bg-center bg-cover" style="background-image: url('assets/images/hero/pages.jpg');">
    <div class="absolute inset-0 bg-black opacity-80"></div>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center mt-12">
            <h3 class="text-4xl leading-normal tracking-wider font-semibold text-white">Job Opening</h3>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><a routerLink="/">Cartzio</a></li>
            <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Job Opening</li>
        </ul>
    </div>
</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div class="lg:col-span-5 md:col-span-6">
                <div class="grid grid-cols-12 gap-6 items-center">
                    <div class="col-span-6">
                        <div class="grid grid-cols-1 gap-6">
                            <img src="assets/images/ab3.jpg" class="shadow rounded-md" alt="">
                            <img src="assets/images/ab4.jpg" class="shadow rounded-md" alt="">
                        </div>
                    </div>

                    <div class="col-span-6">
                        <div class="grid grid-cols-1 gap-6">
                            <img src="assets/images/ab1.jpg" class="shadow rounded-md" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="lg:col-span-7 md:col-span-6">
                <div class="lg:ms-5">
                    <h3 class="mb-6 font-semibold text-3xl leading-normal">We are Largest Job <br> Site in The World</h3>

                    <p class="text-slate-400 max-w-xl mb-2">Upgrade your style with our curated sets. Choose confidence, embrace your unique look.</p>
                    <p class="text-slate-400 max-w-xl">It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. This might also explain why one can now find slightly different versions.</p>
                
                    <div class="mt-6">
                        <a href="#!" class="text-orange-500">Read More <i class="mdi mdi-chevron-right align-middle"></i></a>
                    </div>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="container relative mt-12">
        <div class="grid grid-cols-2 md:grid-cols-4">
            <div class="counter-box relative text-center">
                <h3 class="font-extrabold lg:text-[72px] text-[50px] opacity-5"><span class="counter-value" [countUp]="200">3</span>+K</h3>
                <span class="counter-head font-semibold text-xl absolute top-2/4 start-0 end-0">Total User</span>
            </div><!--end counter box-->

            <div class="counter-box relative text-center">
                <h3 class="font-extrabold lg:text-[72px] text-[50px] opacity-5"><span class="counter-value" [countUp]="15">1</span>+</h3>
                <span class="counter-head font-semibold text-xl absolute top-2/4 start-0 end-0">Years of Experience</span>
            </div><!--end counter box-->

            <div class="counter-box relative text-center">
                <h3 class="font-extrabold lg:text-[72px] text-[50px] opacity-5"><span class="counter-value" [countUp]="54">5</span></h3>
                <span class="counter-head font-semibold text-xl absolute top-2/4 start-0 end-0">Offices in the World</span>
            </div><!--end counter box-->

            <div class="counter-box relative text-center">
                <h3 class="font-extrabold lg:text-[72px] text-[50px] opacity-5"><span class="counter-value" [countUp]="125">12</span>K</h3>
                <span class="counter-head font-semibold text-xl absolute top-2/4 start-0 end-0">No. of Job Positions</span>
            </div><!--end counter box-->
        </div><!--end grid-->
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Job Openings</h3>

            <p class="text-slate-400 max-w-xl mx-auto">Upgrade your style with our curated sets. Choose confidence, embrace your unique look.</p>
        </div><!--end grid-->

        <div class="lg:flex justify-center mt-8">
            <div class="lg:w-3/4">
                <div *ngFor="let item of jobOpening" class="group md:flex items-center justify-between p-6 rounded-lg shadow hover:shadow-lg dark:shadow-gray-700 duration-500 first:mt-0 mt-6">
                    <div>
                        <a class="text-lg font-semibold hover:text-orange-500">{{item.title}}</a>
                        <p class="text-slate-400 mt-1">{{item.openings}}</p>
                    </div>

                    <a class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-orange-500 border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 text-slate-900 dark:text-white hover:text-white rounded-full md:mt-0 mt-4">Apply now</a>
                </div>
            </div>
        </div><!--end-->
    </div><!--end container-->

    <app-mobile-app></app-mobile-app>
</section>