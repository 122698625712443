import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment} from '@enviroments/environment'
import { PaymentMethod } from '@models/payment-method.type';


@Injectable({
  providedIn: 'root'
})

export class PaymentMethodsService {
  
  methods = signal<PaymentMethod[]>([])
  methodsPreferred = signal<PaymentMethod>(new PaymentMethod())

  constructor(private http:HttpClient) {}

  getPaymentMethods(){

    const url = `${environment.apiUrl}/getPaymentMethodByUser`;

    this.http.get<any>(url)
    .subscribe({
      next:(response)=>{

        const items:PaymentMethod[] = response.data

        items.map(item=>{

          if (item.brand == 'Visa') {
            item.imageUrl = 'assets/images/payments/visa.jpg'
          }
          if (item.brand == 'Amex') {
            item.imageUrl = 'assets/images/payments/american-express.jpg'
          }
          if (item.brand == 'Mastercard') {
            item.imageUrl = 'assets/images/payments/mastercard.jpg'
          }
        })

        this.methods.set(items)

        this.methods().map(item =>{
          if (item.preferred) {

            this.methodsPreferred.set(item)
            console.log(this.methodsPreferred())
            
          }
        })

      }
    })

  }

  updateDefaultPaymentMethod(paymentMethod:string){
    const url = `${environment.apiUrl}/updateDefaultPaymentMethod`;
    return this.http.post<Response>(url, {paymentMethod:paymentMethod});
  }

  createPaymentMethod(paymentMethod:string){
    const url = `${environment.apiUrl}/createPaymentMethod`;
    return this.http.post(url, {paymentMethod:paymentMethod});
  }

  deletePaymentMethod(paymentMethod:string){
    const url = `${environment.apiUrl}/deletePaymentMethod`;
    return this.http.post(url, {paymentMethod:paymentMethod});
  }
}
