import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../../components/navbar/navbar.component';
import { TaglineComponent } from '../../../../components/tagline/tagline.component';
import ProductData from '../../../../data/productData.json';
import { ProductDetailTabComponent } from '../../../../components/product-detail-tab/product-detail-tab.component';
import { ProductDetailComponent } from '../../../../components/product-detail/product-detail.component';

@Component({
  selector: 'app-product-detail-one',
  standalone: true,
  imports: [CommonModule,RouterLink,NavbarComponent,TaglineComponent,ProductDetailTabComponent,ProductDetailComponent],
  templateUrl: './product-detail-one.component.html',
  styleUrl: './product-detail-one.component.scss'
})
export class ProductDetailOneComponent {
  product = ProductData
}
