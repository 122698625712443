<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>
<section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 text-center mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Nuestras Tiendas</h3>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="/">Inicio</a></li>
            <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
            <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Sucursales</li>
        </ul>
    </div>
</section>
<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
            <div *ngFor="let item of storeData">
                <img [src]="item.image" class="rounded-md shadow-md dark:shadow-gray-800" alt="">

                <h5 class="font-semibold text-xl leading-normal my-4">{{item.name}}</h5>

                <p class="text-slate-400">{{item.address}} <br> {{item.country}}</p>

                <div class="flex items-center mt-4">
                    <i data-feather="phone" class="w-6 h-6 me-4"></i>
                    <div class="">
                        <h5 class="title font-bold mb-0">Phone</h5>
                        <a href="tel:+152534-468-854" class="tracking-wide text-orange-500">{{item.phone}}</a>
                    </div>
                </div>
                
                <div class="flex items-center mt-4">
                    <i data-feather="map-pin" class="w-6 h-6 me-4"></i>
                    <div class="">
                        <h5 class="title font-bold mb-0">Location</h5>
                        <button (click)="onModalClick()" class="tracking-wide text-center text-orange-500 lightbox">View on Google map</button>
                    </div>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>

<div *ngIf="isModal" class="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
    <div class="w-full h-full px-5 md:px-40 md-py-20 py-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55431.05581015953!2d-95.461302!3d29.735948000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c36647a52ab1%3A0x70a301678672cb!2sBriargrove%20Park%2C%20Houston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1710322657489!5m2!1sen!2sin" width="100%" height="100%" title="myfram" loading="lazy">
        </iframe>
    </div>
    <button class="text-slate-400 absolute top-[20px] right-[20px]" (click)="onModalClick()">
        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="size-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </button>
</div>