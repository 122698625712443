import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule,NavbarComponent],
  templateUrl: './contact.component.html'
})
export class ContactComponent {
  isModal:boolean = false

  onModalClick(){
    this.isModal=!this.isModal;
  }
}
