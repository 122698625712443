<app-navbar [navClass]="'defaultscroll is-sticky'"></app-navbar>

<section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1 text-center mt-14">
            <h3 class="text-3xl leading-normal font-semibold">Nuestras Marcas</h3>
        </div><!--end grid-->
    </div><!--end container-->

    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><a routerLink="index.html">Inicio</a></li>
            <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
            <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">Marcas</li>
        </ul>
    </div>
</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid md:grid-cols-3 grid-cols-1 gap-6">
            <div *ngFor="let item of brandData" class="">
                <ul class="list-none space-y-2">
                    <li *ngFor="let el of item.brands"><a class="text-slate-400 text-lg"><i class="mdi mdi-shopping-outline text-orange-500 me-2"></i>{{el}}</a></li>
                </ul>
            </div>
        </div>
    </div><!--end container-->
</section>
