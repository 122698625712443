import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { TaglineComponent } from '../../../components/tagline/tagline.component';
import { NavbarComponent } from '../../../components/navbar/navbar.component';
import { ClientComponent } from '../../../components/client/client.component';
import ProductData from '../../../data/productData.json';
import { ProductListComponent } from "../../../components/product-list/product-list.component";
import { ProductsService } from '@services/products.service';
import { CategoriesComponent } from "@components/categories/categories.component";


@Component({
  selector: 'app-index-three',
  standalone: true,
  imports: [CommonModule, RouterLink, TaglineComponent, NavbarComponent, ClientComponent, ProductListComponent, CategoriesComponent],
  templateUrl: './index-three.component.html'
})
export class IndexThreeComponent {
  category:string  = null
  private productService = inject(ProductsService)
  
  heroImage = [
    {
      image:'assets/images/categories/ladies-ware.jpg',
      name:'Ropa de Mujer'
    },
    {
      image:'assets/images/categories/kids-ware.jpg',
      name:'Ropa de niños'
    },
    {
      image:'assets/images/categories/mens-ware.jpg',
      name:'Ropa para machos'
    },
  ]

  constructor(){
    this.productService.getShoppingCart()
  }

  
}
