import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../../components/navbar/navbar.component';
import ProductData from '../../../../data/productData.json';

@Component({
  selector: 'app-shop-grid',
  standalone: true,
  imports: [CommonModule, RouterLink, NavbarComponent],
  templateUrl: './shop-grid.component.html'
})
export class ShopGridComponent {
  product = ProductData
}
