import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment} from 'src/app/core/environments/environment'
import { BehaviorSubject, Observable, map, switchMap, take, tap } from 'rxjs';

import { Category } from '@models/category';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})

export class CategoriesService {

  categories = signal<Category[]>([])

  constructor(
    public http: HttpClient,
  ) {}


  public getCategories(){

    const url = `${environment.apiUrl}/getCategories`;

    this.http.post<any>(url, {})
    .subscribe({
      next:(response)=>{

        const categories:Category[] = response.data

        categories.map(item=>{

            if (item.category == 'Hombre') {
                item.path = 'assets/images/categories/mens-ware.jpg'
              }
              if (item.category == 'Mujer') {
                item.path = 'assets/images/categories/ladies-ware.jpg'
              }
              if (item.category == 'Niños') {
                item.path = 'assets/images/categories/kids-ware.jpg'
              }
              if (item.category == 'Smart Watch') {
                item.path = 'assets/images/categories/smart-watch.jpg'
              }
              if (item.category == 'Gafas') {
                item.path = 'assets/images/categories/sunglasses.jpg'
              }

            //item.path = environment.imgUrl + item.path

        })
        
        this.categories.set(categories)

      }
    })
    
  }

}
