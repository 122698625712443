import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../../../components/navbar/navbar.component';
import { TaglineComponent } from '../../../../components/tagline/tagline.component';
import { ProductDetailComponent } from '../../../../components/product-detail/product-detail.component';
import { ProductDetailTabComponent } from '../../../../components/product-detail-tab/product-detail-tab.component';
import ProductData from '../../../../data/productData.json';

import { tns } from 'tiny-slider/src/tiny-slider';

@Component({
  selector: 'app-product-detail-three',
  standalone: true,
  imports: [CommonModule,RouterLink,NavbarComponent,TaglineComponent,ProductDetailComponent,ProductDetailTabComponent],
  templateUrl: './product-detail-three.component.html',
  styleUrl: './product-detail-three.component.scss'
})
export class ProductDetailThreeComponent {
  product = ProductData

  slider: any;

  ngAfterViewInit() {
    const sliderContainer = document.querySelector('.tiny-single-item');
    if (sliderContainer) {
      this.slider = tns({
        container: '.tiny-single-item',
        items: 1,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 16,
      });
    }
  }

  productImage = [
    'assets/images/shop/mens-jecket.jpg','assets/images/shop/mens-jecket-3.jpg','assets/images/shop/mens-jecket-left.jpg','assets/images/shop/mens-jecket-back.jpg','assets/images/shop/mens-jecket-4.jpg'
  ]
}
