<div class="container relative md:mt-24 mt-16">
    <div class="grid grid-cols-1 justify-center text-center mb-6">
        <h5 class="font-semibold text-3xl leading-normal mb-4">Customer Reviews</h5>
        <p class="text-slate-400 max-w-xl mx-auto">Customers love our products and we always strive to please them all.</p>
    </div><!--end grid-->

    <div class="flex justify-center relative mt-20">
        <div class="relative lg:w-1/3 md:w-1/2 w-full">
            <div class="absolute -top-20 md:-start-24 -start-0">
                <i class="mdi mdi-format-quote-open text-9xl opacity-5"></i>
            </div>

            <div class="absolute bottom-28 md:-end-24 -end-0">
                <i class="mdi mdi-format-quote-close text-9xl opacity-5"></i>
            </div>

            <div class="tiny-single-item">
                <div *ngFor="let item of clientData" class="tiny-slide">
                    <div class="text-center">
                        <p class="text-lg text-slate-400 italic"> {{item.desc}} </p>

                        <div class="text-center mt-5">
                            <ul class="text-xl font-medium text-amber-400 list-none mb-2">
                                <li class="inline"><i class="mdi mdi-star"></i></li>
                                <li class="inline"><i class="mdi mdi-star"></i></li>
                                <li class="inline"><i class="mdi mdi-star"></i></li>
                                <li class="inline"><i class="mdi mdi-star"></i></li>
                                <li class="inline"><i class="mdi mdi-star"></i></li>
                            </ul>

                            <img [src]="item.image" class="h-14 w-14 rounded-full shadow-md dark:shadow-gray-700 mx-auto" alt="">
                            <h6 class="mt-2 font-medium">{{item.name}}</h6>
                            <span class="text-slate-400 text-sm">{{item.possition}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><!--end grid-->
</div>
