<app-tagline></app-tagline>
<app-navbar [navClass] = "'defaultscroll is-sticky tagline-height'"></app-navbar>

<section class="swiper-slider-hero relative block h-screen overflow-x-hidden" id="home">
    <div class="swiper-container absolute end-0 top-0 w-full h-full">
        <div class="swiper-wrapper">
            <div class="swiper-slide flex items-center overflow-hidden">
                <div class="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-red-600/5 flex items-center md:bg-top bg-center bg-no-repeat bg-cover;" style="background-image: url('assets/images/hero/bg1.png');">
                    <div class="container relative">
                        <div class="grid md:grid-cols-2 grid-cols-1">
                            <div class="text-start">
                                <span class="uppercase font-semibold text-lg">New Collection</span>
                                <h4 class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3">The Gift Suite</h4>
                                <p class="text-lg">Our latest collection of essential basics.</p>
        
                                <div class="mt-6">
                                    <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-slate-900 dark:bg-orange-500 text-white rounded-md">Shop Now <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div><!--end grid-->
                    </div><!--end container-->
                </div><!-- end slide-inner --> 
            </div> <!-- end swiper-slide -->

            <div class="swiper-slide flex items-center overflow-hidden">
                <div class="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-blue-600/5 flex items-center md:bg-top bg-center bg-no-repeat bg-cover;" style="background-image: url('assets/images/hero/bg2.png');">
                    <div class="container relative">
                        <div class="grid md:grid-cols-2 grid-cols-1">
                            <div class="text-start">
                                <span class="uppercase font-semibold text-lg">Christmas Sale 2023</span>
                                <h4 class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3">End of Season Sale</h4>
                                <p class="text-lg">Our latest collection of essential basics.</p>
        
                                <div class="mt-6">
                                    <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-slate-900 dark:bg-orange-500 text-white rounded-md">View Collection <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div><!--end grid-->
                    </div><!--end container-->
                </div><!-- end slide-inner --> 
            </div> <!-- end swiper-slide -->
        </div>
        <!-- end swiper-wrapper -->

        <!-- swipper controls -->
        <div class="swiper-pagination"></div>
    </div><!--end container-->
</section>

<section class="relative md:py-24 py-16">
    <div class="container relative">
        <div class="grid grid-cols-1 justify-center text-center mb-6">
            <h5 class="font-semibold text-3xl leading-normal mb-4">Featured Collections</h5>
            <p class="text-slate-400 max-w-xl mx-auto">Shop the latest products from the most popular collections</p>
        </div><!--end grid-->

        <div class="grid md:grid-cols-12 grid-cols-1 pt-6 gap-6">
            <div class="lg:col-span-4 md:col-span-6 md:order-1 order-2">
                <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                    <a class="">
                        <img src="assets/images/categories/ladies-ware.jpg" class="h-full w-full object-cover xl:h-[738.66px] lg:h-[614.66px] rounded-md group-hover:scale-110 duration-500" alt="">
                        <span class="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">Ladies Wear</span>
                    </a>
                </div>
            </div><!--end content-->
            
            <div class="lg:col-span-4 md:col-span-12 lg:order-2 order-3">
                <div class="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-6">
                    <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                        <a class="">
                            <img src="assets/images/categories/chappal-shoes.jpg" class="group-hover:scale-110 duration-500" alt="">
                            <span class="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">Chappal & Shoes</span>
                        </a>
                    </div><!--end content-->

                    <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                        <a class="">
                            <img src="assets/images/categories/sunglasses.jpg" class="group-hover:scale-110 duration-500" alt="">
                            <span class="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">Sunglasses</span>
                        </a>
                    </div><!--end content-->
                </div><!--end grid-->
            </div><!--end content-->
            
            <div class="lg:col-span-4 md:col-span-6 lg:order-3 order-2">
                <div class="relative overflow-hidden group rounded-md shadow dark:shadow-gray-800">
                    <a class="">
                        <img src="assets/images/categories/mens-ware.jpg" class="h-full w-full object-cover xl:h-[738.66px] lg:h-[614.66px] rounded-md group-hover:scale-110 duration-500" alt="">
                        <span class="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-4 rounded-md shadow dark:shadow-gray-800 absolute mx-4 bottom-4 text-lg font-medium">Mens Wear</span>
                    </a>
                </div>
            </div><!--end content-->
        </div><!--end grid-->
    </div><!--end container-->

    <div class="container relative md:mt-24 mt-16">
        <div class="grid grid-cols-1 justify-center text-center mb-6">
            <h5 class="font-semibold text-3xl leading-normal mb-4">New Arrival Products</h5>
            <p class="text-slate-400 max-w-xl mx-auto">Shop the latest products from the most popular collections</p>
        </div><!--end grid-->

        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
            <div *ngFor="let item of product | slice : 0 : 8" class="group">
                <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                    <img [src]="item.image" class="group-hover:scale-110 duration-500" alt="">

                    <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                        <a routerLink="/shop-cart" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md">Add to Cart</a>
                    </div>

                    <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                        <li><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="heart" class="size-4"></i></a></li>
                        <li class="mt-1"><a routerLink="/shop-item-detail" class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="eye" class="size-4"></i></a></li>
                        <li class="mt-1"><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="bookmark" class="size-4"></i></a></li>
                    </ul>

                    <ul *ngIf="item.tag" class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'New' " class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'Featured' "  class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-emerald-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>
                </div>

                <div class="mt-4">
                    <a routerLink="/product-detail-one" class="hover:text-orange-500 text-lg font-medium">{{item.name}}</a>
                    <div class="flex justify-between items-center mt-1">
                        <p>{{item.desRate}}<del class="text-slate-400">{{item.rate}}</del></p>
                        <ul class="font-medium text-amber-400 list-none">
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!--end grid-->
    </div><!--end container-->

    <app-cta></app-cta>

    <div class="container relative md:mt-24 mt-16">
        <div class="grid items-end md:grid-cols-2 mb-6">
            <div class="md:text-start text-center">
                <h5 class="font-semibold text-3xl leading-normal mb-4">Best Seller</h5>
                <p class="text-slate-400 max-w-xl">Top sale in this week</p>
            </div>

            <div class="md:text-end">
                <a routerLink="/shop-grid" class="text-slate-400 hover:text-orange-500 hidden md:block">See More Items <i class="mdi mdi-arrow-right"></i></a>
            </div>
        </div><!--end grid-->

        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
            <div *ngFor="let item of product | slice : 8 : 12 " class="group">
                <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                    <img [src]="item.image" class="group-hover:scale-110 duration-500" alt="">

                    <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                        <a routerLink="/shop-cart" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md">Add to Cart</a>
                    </div>

                    <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                        <li><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="heart" class="size-4"></i></a></li>
                        <li class="mt-1"><a routerLink="/shop-item-detail" class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="eye" class="size-4"></i></a></li>
                        <li class="mt-1"><a class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"><i data-feather="bookmark" class="size-4"></i></a></li>
                    </ul>

                    <ul *ngIf="item.tag" class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'New' " class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>

                    <ul *ngIf="item.tagText === 'Featured' "  class="list-none absolute top-[10px] start-4">
                        <li><a class="bg-emerald-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">{{item.tagText}}</a></li>
                    </ul>
                </div>

                <div class="mt-4">
                    <a routerLink="/product-detail-one" class="hover:text-orange-500 text-lg font-medium">{{item.name}}</a>
                    <div class="flex justify-between items-center mt-1">
                        <p>{{item.desRate}}<del class="text-slate-400">{{item.rate}}</del></p>
                        <ul class="font-medium text-amber-400 list-none">
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!--end grid-->
        <div class="grid grid-cols-1 mt-5">
            <div class="text-center">
                <a routerLink="/shop-grid" class="text-slate-400 hover:text-orange-500 block md:hidden">See More Items <i class="mdi mdi-arrow-right"></i></a>
            </div>
        </div>
    </div><!--end container-->

    <app-client></app-client>
</section>
